import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const GoogleAnalytics = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-HPXXTV0GKV';
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-HPXXTV0GKV');
    };

    return () => {
      // Cleanup if necessary
    };
  }, []);

  return (
    <Helmet>
      {/* Additional meta tags or other head elements can be added here */}
    </Helmet>
  );
};

export default GoogleAnalytics;
