import React from 'react';
import CustomNavbar from './Navbar';
import Footer from './footer';

const CancellationPolicy = () => {
  const styles = {
    cancellationPolicy: {
      margin: '7em auto 4em',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      justifyContent: 'center',
      width: '95%'
    },
    heading: {
      color: '#333',
      textAlign: 'center',
      paddingBottom: '2em',
      fontWeight: '700'
    },
    strong: {
      fontWeight: 'bold',
    },
    list: {
      listStyleType: 'none',
      padding: '0',
      width: '80%',
      justifyContent: 'center'
    },
    listItem: {
      marginBottom: '10px',
    },
    unforeseenCircumstances: {
      marginTop: '20px',
    },
  };

  return (
    <div>  
        <CustomNavbar />
        <div style={styles.cancellationPolicy}>
        <h2 style={styles.heading}>Cancellation and Refund Policy</h2>

        <p>
            <strong style={styles.strong}>Refund Overview:</strong> No refund will be issued for the initial booking amount in the event of any cancellations.
        </p>

        <p>
            <strong style={styles.strong}>Cancellation Fees:</strong>
        </p>
        <ul style={styles.list}>
            <li style={styles.listItem}><strong style={styles.strong}>30 or more days before the trip:</strong> 50% of the trip cost will be charged as cancellation fees.</li>
            <li style={styles.listItem}><strong style={styles.strong}>15-30 days before the trip:</strong> 75% of the trip cost will be charged as cancellation fees.</li>
            <li style={styles.listItem}><strong style={styles.strong}>0-15 days before the trip:</strong> 100% of the trip cost will be charged as cancellation fees.</li>
        </ul>

        <p style={styles.unforeseenCircumstances}>
            <strong style={styles.strong}>Unforeseen Circumstances:</strong> In the case of unforeseen weather conditions or government restrictions, certain activities may be canceled. The operator will make every effort to provide an alternate feasible activity. However, no refund will be provided for such circumstances.
        </p>
        </div>
        <Footer />
    </div>
  );
};

export default CancellationPolicy;
