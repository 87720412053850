import React, { useState, useEffect } from 'react';
import './customise.css'
import { database } from './firebase';
import { onValue, ref, push, get } from "firebase/database";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; 
import coupleSelect from './images/coupleSelect.png'
import familySelect from './images/familySelect.png'
import friendsSelect from './images/friendsSelect.png'
import fourDays from './images/0-4Days.png'
import eightDays from './images/5-8Days.png'
import twelveDays from './images/9-12days.png'
import twelvePlusDays from './images/12+days.png'
import soloSelect from './images/soloSelect.png'
import twoStar from './images/2Star.png'
import threeStar from './images/3Star.png'
import fourStar from './images/4Star.png'
import fiveStar from './images/5Star.png' 
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomNavbar from './Navbar';
import { useMediaQuery } from 'react-responsive';
import emailjs from '@emailjs/browser';
import { isMobile } from 'react-device-detect';

function Customise() {

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState('');
  const [searchInputs, setSearchInputs] = useState('');
  const [destinations, setDestinations] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState('');
  const [pickedDestination, setPickedDestination] = useState(false);
  const [departureSelect, setDepartureSelect] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedPeople, setSelectedPeople] = useState(1)
  const [selectedType, setSelectedType] = useState(null)
  const [selectedPrice, setSelectedPrice] = useState(null)
  const [pickedDate, setPickedDate] = useState(false);
  const [pickedDuration, setPickedDuration] = useState(false);
  const [pickedPeople, setPickedPeople] = useState(false);
  const [pickedPrice, setPickedPrice] = useState(false)
  const [credentialsSubmit, setCredentialsSubmit] = useState(false)
  const [indianCities, setIndianCities] = useState([])
  const [kids, setKids] = useState(0);
  const [infants, setInfants] = useState(0);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setPickedDate(!pickedDate);
  };

  useEffect(() => {

    const query = ref(database, "destinations");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()) {
        Object.values(data).map((destination) => {
          setDestinations((destinations) => [...destinations, destination]);
        });
      }
    });
  }, []);

  useEffect(() => {

  const query = ref(database, "indian-cities");
  return onValue(query, (snapshot) => {
    const data = snapshot.val();

    if (snapshot.exists()) {
      Object.values(data).map((indianCity) => {
        setIndianCities((indianCities) => [...indianCities, indianCity]);
      });
    }
  });
  }, []);

  const [formData, setFormData] = useState({
    email: '',
    phoneNumber: '',
    suggestion: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const credentialsReceived = () => {
    if (validateEmail(formData.email) && validatePhoneNumber(formData.phoneNumber) && formData.name) {
       setCredentialsSubmit(!credentialsSubmit)
    } else {
      alert("Please fill properly")
    }
  }
  
  const filteredItems = destinations.filter(item =>
    item.toLowerCase().includes(searchInput.toLowerCase())
  );

  const filterCities = indianCities.filter(item =>
    item.toLowerCase().includes(searchInputs.toLowerCase())
  );

  const destinationPick = (selectedCountry) => {
    setSearchInput(selectedCountry)
    setPickedDestination(!pickedDestination);
  };

  const departurePick = (selectedCity) => {
    setSearchInputs(selectedCity)
    setDepartureSelect(!departureSelect)
  }

  const handleDurationSelection = (duration) => {
    setSelectedDuration(duration);
    setPickedDuration(!pickedDuration);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleModal = (type) => {
    setSelectedType(type);
    handleShow()
  }

  const handlePeopleSelection = (type, people) => {
    setSelectedType(type);
    setSelectedPeople(people)
    setPickedPeople(!pickedPeople);
  };

  const handleAdultsChange = (value) => {
    setSelectedPeople(Math.max(1, selectedPeople + value)); // Ensure adults never go below 1
  };

  const handleKidsChange = (value) => {
    setKids(Math.max(0, kids + value)); // Ensure kids never go below 0
  };

  const handleInfantsChange = (value) => {
    setInfants(Math.max(0, infants + value)); // Ensure infants never go below 0
  };

  const handlePeopleSelection1 = () => {
    setPickedPeople(!pickedPeople);
    handleClose()
  };

  const handlePriceSelection = (requirement) => {
    setSelectedPrice(requirement)
    setPickedPrice(!pickedPrice)
  }

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    navigate('/');
  };

  const saveDataToFirebase = (e) => {
    e.preventDefault();

    const dataToStore = {
        destination: searchInput,
        date: selectedDate.toISOString(),
        duration: selectedDuration,
        type: selectedType,
        adults: selectedPeople,
        kids: kids,
        infants: infants,
        departure: searchInputs,
        price: selectedPrice,
        name: formData.name,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        suggestion: formData.suggestion,
    };

    const dataRef = ref(database, 'customise_querry');

    push(dataRef, dataToStore)
      .then(() => {
        console.log('Data has been stored in Firebase.');

        // Send email using EmailJS with the data from Firebase
        emailjs.send('service_y6etgxl', 'template_5jum5vk', {
          to_email: 'info@fliptotrip.com',
          subject: 'New Trip Customisation Request',
          firebaseData: formatEmailBody(dataToStore),
        }, 'SjRd2OL96xw44oUcE') 
          .then((result) => {
            console.log('Email sent successfully:', result.text);
          })
          .catch((error) => {
            console.error('Error sending email:', error.text);
          });

        setShowModal(true);
      })
      .catch((error) => {
        console.error('Error storing data in Firebase:', error);
      });
  };

  const formatEmailBody = (data) => {
    return `
    Destination: ${data.destination}
    Date: ${data.date}
    Duration: ${data.duration}
    Type: ${data.type}
    Adults: ${data.adults}
    Kids: ${data.kids}
    Infants: ${data.infants}
    Departure: ${data.departure}
    Price: ${data.price}
    Name: ${data.name}
    Email: ${data.email}
    Phone Number: ${data.phoneNumber}
    Suggestion: ${data.suggestion}     
    `;
  };

  const renderCustomDayContents = (day, date) => {
        
    return (
      <div style={{height: '20px', fontSize: '16px'}}>
        <span>{day}</span>
        <br />
      </div>
    );
  };
  
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const filterDate = (date) => {
    if (currentMonth.getMonth() !== date.getMonth()) {
      return false;
    }
    return true;
  };

  const handleMonthChange = (date) => {
    setCurrentMonth(date);
  };

  const CustomInput = ({ value, onClick, label, placeholder }) => (
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: '17em'}}>
              {label && <label style={{ fontSize: '14px' }}>{label}</label>}
              <input
                type="text"
                value={value}
                onClick={onClick}
                placeholder={placeholder}
                style={{ border: '0px solid #ccc', borderRadius: '4px', fontSize: '1px', padding: '1px', backgroundColor: 'transparent' }}
                readOnly
              />
            </div>
    );

  return (
    <div>
      <CustomNavbar />
      {!pickedDestination ? (
        <><h2 className='destination-head'>Which location are you considering for your next vacation?</h2><div>
          <div className="input-group">
            <div className='destination-input'>
              <input
                type="text"
                className="form-control"
                style={{ fontSize: '1.5em', borderRadius: '0.5em', padding: '0.5em', width: '70%' }}
                placeholder="Pick Your Destination"
                aria-label="Search"
                aria-describedby="basic-addon2"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)} />
            </div>
            <div className='items'>
              {filteredItems.slice(0, 5).map((item, index) => (
                <button key={index} onClick={() => destinationPick(item)} className='places-list'><p className='button-text'>{item}</p></button>
              ))}
            </div>
          </div>
        </div></>
      )
      : (
      !selectedDate ? (
      <div>
        <h2 className='destination-head1'>When is your departure date?</h2>
        <div className='date-div'>
          <DatePicker
            selected={selectedDate}
            onMonthChange={handleMonthChange}
            onChange={handleDateChange}
            minDate={new Date()} 
            dateFormat="dd/MM/yyyy"
            open={true}
            renderDayContents={renderCustomDayContents}
            filterDate={filterDate}
            customInput={<CustomInput />}
            calendarClassName="custom-calendar"
          />
        </div>
      </div>
      ) : (
        !selectedDuration ? (
        <div>
          <h2 className='destination-head'>What's the duration of your holiday?</h2>
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <button className="box" onClick={() => handleDurationSelection('1-4 days')}>
                  <div className='trip-type-select'>
                    <div style={{paddingTop: '1.5em'}}>
                      <img src={fourDays} alt="Fast Trips" className='couple-select'/>
                      <div style={{paddingTop: '1.5em'}}>
                        <p style={{fontWeight: '700'}}>1-4 days</p> 
                      </div> 
                    </div>
                  </div>
                </button>
              </div>
              <div className="col-md-3">
                <button className="box" onClick={() => handleDurationSelection('5-8 days')}>
                <div className='trip-type-select'>
                    <div style={{paddingTop: '1.5em'}}>
                      <img src={eightDays} alt="Standard Trips" className='couple-select'/>
                      <div style={{paddingTop: '1.5em'}}>
                        <p style={{fontWeight: '700'}}>5-8 days</p> 
                      </div>  
                    </div>
                </div>
                </button>
              </div>
              <div className="col-md-3">
                <button className="box" onClick={() => handleDurationSelection('9-12 days')}>
                <div className='trip-type-select'>
                    <div style={{paddingTop: '1.5em'}}>
                      <img src={twelveDays} alt="Travel Around main attractions" className='couple-select'/>
                      <div style={{paddingTop: '1.5em'}}>
                        <p style={{fontWeight: '700'}}>9-12 days</p> 
                      </div>  
                    </div>
                </div>
                </button>
              </div>
              <div className="col-md-3">
                <button className="box" onClick={() => handleDurationSelection('12+ days')}>
                <div className='trip-type-select'>
                    <div style={{paddingTop: '1.5em'}}>
                      <img src={twelvePlusDays} alt="Leisure Trips" className='couple-select'/>
                      <div style={{paddingTop: '1.5em'}}>
                        <p style={{fontWeight: '700'}}>12+ days</p> 
                      </div>  
                    </div>
                </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        ) : (
          !pickedPeople ? (
            <div>
              <h2 className='destination-head'>Who is travelling with you?</h2>
              <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <button className="box" onClick={() => handleModal('Family')}>
                    <div className='trip-type-select'>
                      <img src={familySelect} alt="Family Trips" className='couple-select'/>
                      <div style={{paddingTop: '1.5em'}}>
                        <p style={{fontWeight: '700'}}>Family</p> 
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-md-3">
                  <button className="box" onClick={() => handlePeopleSelection('Couple', 2)}>
                    <div className='trip-type-select'>
                      <img src={coupleSelect} alt="Couple Trips" className='couple-select'/>
                      <div style={{paddingTop: '1.5em'}}>
                        <p style={{fontWeight: '700'}}>Couple</p> 
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-md-3">
                  <button className="box" onClick={() => handleModal('Friends')}>
                    <div className='trip-type-select'>
                      <img src={friendsSelect} alt="Friends Trip" className='couple-select'/>
                      <div style={{paddingTop: '1.5em'}}>
                        <p style={{fontWeight: '700'}}>Friends</p> 
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-md-3">
                  <button className="box" onClick={() => handlePeopleSelection('Solo', 1)}>
                    <div className='trip-type-select'>
                      <img src={soloSelect} alt="Solo Travel" className='couple-select'/>
                      <div style={{paddingTop: '1.5em'}}>
                        <p style={{fontWeight: '700'}}>Solo</p> 
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            </div>
          ) : (
          !departureSelect ? (
            <div>
            <h2 className='destination-head'>Where are you travelling from?</h2>
            <div className="input-group">
              <div className='destination-input'>
                <input
                  type="text"
                  className="form-control"
                  style={{ fontSize: '1.5em', borderRadius: '0.5em', padding: '0.5em', width: '70%' }}
                  placeholder="Select your Departure"
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                  value={searchInputs}
                  onChange={(e) => setSearchInputs(e.target.value)} />
              </div>
              <div className='items'>
                {filterCities.slice(0, 5).map((item, index) => (
                  <button key={index} onClick={() => departurePick(item)} className='places-list'><p className='button-text'>{item}</p></button>
                ))}
                {filterCities.length === 0 && <button className='places-list' onClick={() => departurePick('Other')}>Other <sup style={{ color: 'red' }}>(Please select this option when the following city is not mentioned)</sup></button>}
              </div>
            </div>
          </div>
          ) : (
          !pickedPrice ? (
            <div>
            <h2 className='destination-head'>What is your Travel Budget?</h2>
            <div>
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <button className="box" onClick={() => handlePriceSelection('Budget')}>
                    <div className='trip-type-select'>
                      <img src={twoStar} alt="Budget Trips" className='couple-select'/>
                      <div style={{paddingTop: '1.5em'}}>
                        <p style={{fontWeight: '700'}}>Budget</p> 
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-md-3">
                  <button className="box" onClick={() => handlePriceSelection('Moderate')}>
                    <div className='trip-type-select'>
                      <img src={threeStar} alt="Moderate Budget Tour" className='couple-select'/>
                      <div style={{paddingTop: '1.5em'}}>
                        <p style={{fontWeight: '700'}}>Moderate</p> 
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-md-3">
                  <button className="box" onClick={() => handlePriceSelection('Semi-Luxury')}>
                    <div className='trip-type-select'>
                      <img src={fourStar} alt="Semi Luxury Trips" className='couple-select'/>
                      <div style={{paddingTop: '1.5em'}}>
                        <p style={{fontWeight: '700'}}>Semi-Luxury</p> 
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-md-3">
                  <button className="box" onClick={() => handlePriceSelection('Luxury')}>
                    <div className='trip-type-select'>
                      <img src={fiveStar} alt="Luxury Trips" className='couple-select'/>
                      <div style={{paddingTop: '1.5em'}}>
                        <p style={{fontWeight: '700'}}>Luxury</p> 
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            </div>
          </div>
          ) : (
            !credentialsSubmit ? (
              <div>
              <h2 className='destination-head'>Your Contact Details</h2>
              <div className="container mt-5">
                <form onSubmit={handleSubmit} style={{width: '50%'}}>
                  <div className="form-group">
                    <label htmlFor="email">Name <spam style={{color: 'red', fontWeight: '900'}}>*</spam></label>
                    <input
                      type="name"
                      className="form-control"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                      style={{ borderRadius: '8px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)'}}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email <spam style={{color: 'red', fontWeight: '900'}}>*</spam></label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                      style={{ borderRadius: '8px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)'}}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phoneNumber">Phone Number <spam style={{color: 'red', fontWeight: '900'}}>*</spam></label>
                    <input
                      type="tel"
                      className="form-control"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      required
                      style={{ borderRadius: '8px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)'}}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary" onClick={credentialsReceived}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
            ) : (
              <div>
                <h2 className='destination-head'>What is your Travel Plan/Requirements?</h2>
                <div className="container mt-5">
                  <form onSubmit={handleSubmit1} style={{width: '80%'}}>
                    <div className="form-group">
                      <label htmlFor="suggestion">Suggestion</label>
                      <textarea
                        className="form-control"
                        id="suggestion"
                        name="suggestion"
                        value={formData.suggestion}
                        onChange={handleInputChanges}
                        rows="4"
                        required
                        style={{ borderRadius: '8px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)'}}
                      />
                    </div>
                    <button type="submit" className="btn btn-primary" onClick={saveDataToFirebase}>
                      Enquire Now
                    </button>
                  </form>
                </div>
              </div>
            )
          )
          )
        )
        )
      )
    )}  
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Select Number of People</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>ADULTS (12y +)</label>
          <div>
            <Button variant="outline-primary" onClick={() => handleAdultsChange(-1)}>
              -
            </Button>{' '}
            {selectedPeople}{' '}
            <Button variant="outline-primary" onClick={() => handleAdultsChange(1)}>
              +
            </Button>
          </div>
        </div>
        <div>
          <label>CHILDREN (2y - 12y )</label>
          <div>
            <Button variant="outline-primary" onClick={() => handleKidsChange(-1)}>
              -
            </Button>{' '}
            {kids}{' '}
            <Button variant="outline-primary" onClick={() => handleKidsChange(1)}>
              +
            </Button>
          </div>
        </div>
        <div>
          <label>INFANTS (below 2y)</label>
          <div>
            <Button variant="outline-primary" onClick={() => handleInfantsChange(-1)}>
              -
            </Button>{' '}
            {infants}{' '}
            <Button variant="outline-primary" onClick={() => handleInfantsChange(1)}>
              +
            </Button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handlePeopleSelection1}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
    <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Form Submitted Successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          We will be contacting you within 24 hours. Thank you!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
    </Modal>
    </div>
  );
}

export default Customise;