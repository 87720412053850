import React, { useState } from 'react';
import './Navbar.css'; // Import your CSS file
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from './images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faYoutube,
  faInstagram,
  faLinkedin,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons';

const CustomNavbar = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const toggleOffcanvas = () => {
    setShowOffcanvas(prev => !prev);
  };

  return (
    <>
      <Navbar expand="lg" className="custom-navbar" fixed="top">
        <Navbar.Brand href="/">
          <img
            src={logo}
            height="60em"
            width="200em"
            href="/"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleOffcanvas} style={{marginRight: '0.8em'}}/>
        <Navbar.Collapse id="basic-navbar-nav" style={{marginLeft: '1em'}}>
          <Nav className="me-auto my-2 my-lg-0">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/customise-trip">Customise Trip</Nav.Link>
            <Nav.Link href="/visa">Visa & Travel Insurance</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
        <div className="social-media-links">
                <a href="https://www.facebook.com/fliptotriptravel/" target='_blank'>
                    <FontAwesomeIcon icon={faFacebook} style={{color: 'rgb(35, 35, 142)'}}/>
                </a>
                <a href="https://www.instagram.com/flip2trip/" target='_blank'>
                    <FontAwesomeIcon icon={faInstagram} style={{color: '#C13584'}}/>
                </a>
                <a href="https://www.linkedin.com/company/fliptotrip/" target='_blank'>
                    <FontAwesomeIcon icon={faLinkedin} />
                </a>
                <a href="https://www.youtube.com/@fliptotrip" target='_blank'>
                    <FontAwesomeIcon icon={faYoutube} style={{color: 'red'}}/>
                </a>
                <a href="https://api.whatsapp.com/send/?phone=919740096300&text&type=phone_number&app_absent=0" target="_blank">
                    <FontAwesomeIcon icon={faWhatsapp} style={{color: 'green'}}/>
                </a>
            </div>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default CustomNavbar;
