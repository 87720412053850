import React from 'react';
import './App.css'; 
import CustomNavbar from './Navbar';
import Footer from './footer';

const PrivacyPolicy = () => {
  const styles = {
    privacyPolicy: {
      margin: '6em auto 3em',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      width: '85%'
    },
    heading: {
      color: '#333',
      textAlign: 'center',
      paddingBottom: '1em',
      fontWeight: '700'
    },
    paragraph: {
      color: '#555',
      lineHeight: '1.5',
    },
    strong: {
      fontWeight: 'bold',
    },
    list: {
      listStyleType: 'none',
      padding: '0',
    },
    listItem: {
      marginBottom: '10px',
    },
    contact: {
      marginTop: '20px',
    },
    link: {
      color: '#007bff',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  };

  return (
  <div>
    <CustomNavbar />
     <div style={styles.privacyPolicy}>
      <h2 style={styles.heading}>Privacy Policy</h2>

      <p style={styles.paragraph}>
        The terms “We” / “Us” / “Our”/”Company” individually and collectively refer to Flip to Trip,
        and the terms “You” /”Your” / “Yourself” refer to the users.
      </p>

      <p style={styles.paragraph}>
        This Privacy Policy is an electronic record in the form of an electronic contract formed under
        Information Technology Act, 2000 and the rules made thereunder and the amended provisions
        pertaining to electronic documents/records in various statutes as amended by the Information
        Technology Act, 2000. This Privacy Policy does not require any physical, electronic, or
        digital signature.
      </p>

      <p style={styles.paragraph}>
        This Privacy Policy is a legally binding document between you and Flip to Trip (both terms defined below).
        The terms of this Privacy Policy will be effective upon your acceptance of the same (directly or indirectly
        in electronic form, by clicking on the I accept tab or by use of the website or by other means) and will
        govern the relationship between you and Flip to Trip for your use of the website “www.fliptotrip.com”
        (defined below).
        </p>

        <p style={styles.paragraph}>
        This document is published and shall be construed in accordance with the provisions of the Information
        Technology (reasonable security practices and procedures and sensitive personal data or information) rules,
        2011 under the Information Technology Act, 2000; that require publishing of the Privacy Policy for
        collection, use, storage, and transfer of sensitive personal data or information.
        </p>

        <p style={styles.paragraph}>
        Please read this Privacy Policy carefully by using the Website, you indicate that you understand, agree,
        and consent to this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do
        not use this Website.
        </p>

        <p style={styles.paragraph}>
        By providing us your Information or by making use of the facilities provided by the Website, You hereby
        consent to the collection, storage, processing, and transfer of any or all of Your Personal Information
        and Non-Personal Information by us as specified under this Privacy Policy. You further agree that such
        collection, use, storage, and transfer of Your Information shall not cause any loss or wrongful gain to
        you or any other person.
        </p>

      <p style={styles.paragraph}>
    <strong style={styles.strong}>USER INFORMATION</strong>
    </p>
    <p style={styles.contact}>
    To avail certain services on our Websites, users are required to provide certain information for the registration process namely: – a) your name, b) email address, c) sex, d) age, e) PIN code, f) credit card or debit card details g) medical records and history h) sexual orientation, i) biometric information, j) password etc., and / or your occupation, interests, and the like. The Information as supplied by the users enables us to improve our sites and provide you the most user-friendly experience.
    </p>
    <p style={styles.contact}>All required information is service dependent and we may use the above-said user information to maintain, protect, and improve our services (including advertising services) and for developing new services.</p>
    <p style={styles.contact}>Such information will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005 or any other law for the time being in force.</p>
    <p style={styles.paragraph}>
    <strong style={styles.strong}>COOKIES</strong>
    </p>
    <p style={styles.contact}>
    To improve the responsiveness of the sites for our users, we may use “cookies”, or similar electronic tools to collect information to assign each visitor a unique, random number as a User Identification (User ID) to understand the user’s individual interests using the Identified Computer. Unless you voluntarily identify yourself (through registration, for example), we will have no way of knowing who you are, even if we assign a cookie to your computer. The only personal information a cookie can contain is information you supply (an example of this is when you ask for our Personalised Horoscope). A cookie cannot read data off your hard drive. Our advertisers may also assign their own cookies to your browser (if you click on their ads), a process that we do not control.
    </p>
    <p style={styles.contact}>
    Our web servers automatically collect limited information about your computer’s configuration when you visit our website, including the type of browser software you use, the operating system you are running, the resolution of your computer screen, and the website that referred you to us. We may also collect information about your browsing behavior, such as the pages you view, the links you click, and other actions you take on our website.
    </p>
    <p style={styles.paragraph}>
    <strong style={styles.strong}>USE OF INFORMATION</strong>
    </p>
    <p style={styles.contact}>
    We use your personal information to provide you with our services and to improve and customize your experience with us. We may also use your information to send you marketing and promotional materials, as well as to conduct research and analysis to better understand our users’ needs.
    </p>
    <p style={styles.contact}>
    We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, and other similar services. These service providers are required to protect your personal information and may only use it for the purposes for which it was disclosed.
    </p>
    <p style={styles.contact}>
    We may also disclose your information if required to do so by law, or if we believe in good faith that such action is necessary to comply with legal obligations or to protect our rights or the rights of others.
    </p>
    <p style={styles.paragraph}>
    <strong style={styles.strong}>PROTECTION OF INFORMATION</strong>
    </p>
    <p style={styles.contact}>
    We take the security of your personal information seriously and have implemented reasonable technical and organizational measures to protect your information from unauthorized access, use, alteration, or disclosure.
    </p>
    <p style={styles.contact}>
    We store your personal information for as long as necessary to provide you with our services and as required by applicable laws and regulations.
    </p>
    <p style={styles.paragraph}>
    <strong style={styles.strong}>CONSENT TO TRANSFER</strong>
    </p>
    <p style={styles.contact}>
    By using our website, you consent to the transfer of your personal information to any country in the world where we have facilities or in which we engage service providers. We will take appropriate measures to ensure that your personal information receives an adequate level of protection in these countries.
    </p>
    <p style={styles.paragraph}>
    <strong style={styles.strong}>CHANGES TO PRIVACY POLICY</strong>
    </p>
    <p style={styles.contact}>
    We reserve the right to modify this Privacy Policy at any time without prior notice. Any changes will be effective immediately upon posting of the updated Privacy Policy on our website. Your continued use of our website after the posting of any revised Privacy Policy shall constitute your consent to such changes.
    </p>
    <p style={styles.contact}>
        <strong style={styles.strong}>CONTACT US:</strong> If you have any questions or concerns about
        our Privacy Policy or the way we handle your personal information, please contact us at{' '}
        <a href="mailto:contact@fliptotrip.com" style={styles.link}>contact@fliptotrip.com</a>.
      </p>
    </div>
    <Footer />
  </div>
  );
};

export default PrivacyPolicy;
