import {React, useState} from "react";
import './visa.css';
import CustomNavbar from './Navbar';
import Footer from "./footer";
import './Home.css';
import { Button, Navbar, Form, Modal } from "react-bootstrap";
//import DatePicker from 'react-datepicker';
import { database } from './firebase';
import { ref, push } from "firebase/database";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import backgroundImage1 from './images/visaBackground.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faSearch, faPlane, faUser, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';
import mobileBackgroundImage from './images/visaBackgroundMobile.jpg'
import DatePicker, { Calendar } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import emailjs from '@emailjs/browser';

function Visa() {

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    const visaOptions = [
        { code: 'AR', name: 'Argentina' },
        { code: 'AU', name: 'Australia' },
        { code: 'AT', name: 'Austria' },
        { code: 'BE', name: 'Belgium' },
        { code: 'BT', name: 'Bhutan' },
        { code: 'BR', name: 'Brazil' },
        { code: 'BG', name: 'Bulgaria' },
        { code: 'KH', name: 'Cambodia' },
        { code: 'CA', name: 'Canada' },
        { code: 'CL', name: 'Chile' },
        { code: 'CN', name: 'China' },
        { code: 'CR', name: 'Costa Rica' },
        { code: 'HR', name: 'Croatia' },
        { code: 'CY', name: 'Cyprus' },
        { code: 'CZ', name: 'Czech Republic' },
        { code: 'DK', name: 'Denmark' },
        { code: 'EG', name: 'Egypt' },
        { code: 'EE', name: 'Estonia' },
        { code: 'FI', name: 'Finland' },
        { code: 'FR', name: 'France' },
        { code: 'GE', name: 'Georgia' },
        { code: 'DE', name: 'Germany' },
        { code: 'GR', name: 'Greece' },
        { code: 'HK', name: 'Hong Kong' },
        { code: 'HU', name: 'Hungary' },
        { code: 'IS', name: 'Iceland' },
        { code: 'ID', name: 'Indonesia' },
        { code: 'IE', name: 'Ireland' },
        { code: 'IL', name: 'Israel' },
        { code: 'IT', name: 'Italy' },
        { code: 'JP', name: 'Japan' },
        { code: 'JO', name: 'Jordan' },
        { code: 'KZ', name: 'Kazakhstan' },
        { code: 'KE', name: 'Kenya' },
        { code: 'KG', name: 'Kyrgyzstan' },
        { code: 'LA', name: 'Laos' },
        { code: 'LV', name: 'Latvia' },
        { code: 'LI', name: 'Liechtenstein' },
        { code: 'LT', name: 'Lithuania' },
        { code: 'LU', name: 'Luxembourg' },
        { code: 'MO', name: 'Macau' },
        { code: 'MY', name: 'Malaysia' },
        { code: 'MV', name: 'Maldives' },
        { code: 'MT', name: 'Malta' },
        { code: 'MU', name: 'Mauritius' },
        { code: 'MX', name: 'Mexico' },
        { code: 'MC', name: 'Monaco' },
        { code: 'MN', name: 'Mongolia' },
        { code: 'MA', name: 'Morocco' },
        { code: 'MM', name: 'Myanmar' },
        { code: 'NP', name: 'Nepal' },
        { code: 'NL', name: 'Netherlands' },
        { code: 'NZ', name: 'New Zealand' },
        { code: 'NO', name: 'Norway' },
        { code: 'PE', name: 'Peru' },
        { code: 'PH', name: 'Philippines' },
        { code: 'PL', name: 'Poland' },
        { code: 'PT', name: 'Portugal' },
        { code: 'RU', name: 'Russia' },
        { code: 'SM', name: 'San Marino' },
        { code: 'SA', name: 'Saudi Arabia' },
        { code: 'SG', name: 'Singapore' },
        { code: 'SK', name: 'Slovakia' },
        { code: 'SI', name: 'Slovenia' },
        { code: 'ZA', name: 'South Africa' },
        { code: 'KR', name: 'South Korea' },
        { code: 'ES', name: 'Spain' },
        { code: 'LK', name: 'Sri Lanka' },
        { code: 'SE', name: 'Sweden' },
        { code: 'CH', name: 'Switzerland' },
        { code: 'TJ', name: 'Tajikistan' },
        { code: 'TZ', name: 'Tanzania' },
        { code: 'TH', name: 'Thailand' },
        { code: 'TN', name: 'Tunisia' },
        { code: 'TR', name: 'Turkey' },
        { code: 'GB', name: 'United Arab Emirates' },
        { code: 'GB', name: 'United Kingdom' },
        { code: 'US', name: 'United States' },
        { code: 'UZ', name: 'Uzbekistan' },
        { code: 'VN', name: 'Vietnam' },
        { code: 'ZW', name: 'Zimbabwe' }
    ];

    const [selectedCountry, setSelectedCountry] = useState('');
    const [departureDate, setDepartureDate] = useState(null);
    const [arrivalDate, setArrivalDate] = useState(null);
    const [selectedPeople, setSelectedPeople] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [suggestion, setSuggestion] = useState('')
    const [countrySelected, setCountrySelected] = useState(false);
    const [peopleSelected, setPeopleSelected] = useState(false);
    const [departureSelect, setDepartureSelect] = useState(false);

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    const handleSuggestionChange = (e) => {
        setSuggestion(e.target.value);
    };

    const [show, setShow] = useState(false);

    const [showBar, setShowBar] = useState(false);

    const handleClose = () => {
      setShow(false);
      setCountrySelected(false)
      setPeopleSelected(false)
      setDepartureSelect(false)
      setSelectedCountry('')
      setDepartureDate(null)
      setArrivalDate(null)
      setSelectedPeople('')
    }
    
    const handleShow = () => setShow(true);

    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleShowModal = () => {
        setShowModal(true)
    }

    const handleShowBar = () => {
        setShowBar(true)
    }

    const handleSubmit1 = () => {
        if (selectedCountry && selectedPeople && departureDate && arrivalDate) {   
            handleShow();
        } else {
          alert('Please fill in all the required fields');
        }
    };

    const SelectDestination = ({ selectedDestination, onDestinationChange }) => {
        return (
          <div className="select-box" style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faPlane} style={{marginRight: '8px'}}/>
            <select
              id="destination"
              value={selectedDestination}
              onChange={(e) => onDestinationChange(e.target.value)}
            >
              <option value="" disabled>Going To</option>
              {visaOptions.map((country) => (
                <option key={country.code} value={country.name}>
                {country.name}
                </option>
              ))}
            </select>
          </div>
        );
      };

      const handleDestinationChange = (value) => {
        setCountrySelected(true);
        setSelectedCountry(value);
      };

      const SelectDestinationMobile = ({ selectedDestination }) => {
        return (
          <div className="select-box" style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faPlane} style={{marginRight: '8px'}}/>
            <select
              id="destinationMobile"
              value={selectedDestination}
              onChange={(e) => handleDestinationChange(e.target.value)}
            >
              <option value="" disabled style={{color: 'black'}}>Going To</option>
              {visaOptions.map((country) => (
                <option key={country.code} value={country.name}>
                {country.name}
                </option>
              ))}
            </select>
          </div>
        );
      };
      
      const SelectPassengerCount = ({ passengerCount, onPassengerCountChange }) => {
        return (
            <div className="select-box" style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faUser} style={{marginRight: '8px'}}/>
            <select
              id="passengerCount"
              value={passengerCount}
              onChange={(e) => onPassengerCountChange(e.target.value)}
            >
              <option value="" disabled>Total Passengers</option>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, '9+'].map((number) => (
                <option key={number} value={number}>
                    {number}
                </option>
                ))}
            </select>
          </div>
        );
      };

      const handlePeopleChange = (value) => {
        setPeopleSelected(true);
        setSelectedPeople(value);
      };

      const SelectPassengerCountMobile = ({ passengerCount }) => {
        return (
            <div className="select-box" style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faUser} style={{marginRight: '8px'}}/>
            <select
              id="passengerCountMobile"
              value={passengerCount}
              onChange={(e) => handlePeopleChange(e.target.value)}
            >
              <option value="" disabled style={{color: 'black'}}>Total Passengers &nbsp; &nbsp;</option>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, '9+'].map((number) => (
                <option key={number} value={number}>
                    {number}
                </option>
                ))}
            </select>
          </div>
        );
      };

      const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
    
      const validatePhoneNumber = (phoneNumber) => {
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phoneNumber);
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name && validateEmail(email) && validatePhoneNumber(phone)) {
            const dataToStore = {
                name: name,
                email: email,
                phone: phone,
                startDate: departureDate.toISOString(),
                endDate: arrivalDate.toISOString(),
                country: selectedCountry,
                travelers: selectedPeople,
                suggestion: suggestion
            };
          
            const dataRef = ref(database, "visa-querry"); 
            push(dataRef, dataToStore)
            .then(() => {
              console.log('Data has been stored in Firebase.');

              // Send email using EmailJS with the data from Firebase
              emailjs.send('service_y6etgxl', 'template_o72mzui', {
                to_email: 'info@example.com', // Change to the desired recipient email
                subject: 'New Visa Inquiry',
                name: dataToStore.name,
                email: dataToStore.email,
                phone: dataToStore.phone,
                startDate: dataToStore.startDate,
                endDate: dataToStore.endDate,
                country: dataToStore.country,
                travelers: dataToStore.travelers,
                suggestion: dataToStore.suggestion,
              }, 'SjRd2OL96xw44oUcE')
                .then((result) => {
                  console.log('Email sent successfully:', result.text);
                })
                .catch((error) => {
                  console.error('Error sending email:', error.text);
                });
            })
            .catch((error) => {
              console.error('Error storing data in Firebase:', error);
            });
            setName('')
            setEmail('')
            setPhone('')
            setSuggestion('')
            setSelectedCountry('')
            setDepartureDate(null)
            setArrivalDate(null)
            setSelectedPeople('')
            handleClose()
            handleShowModal()

        } else {
            alert('Please fill in all the required fields properly');
        }
      };

        const minArrivalDate = departureDate ? new Date(departureDate.getTime() + 24 * 60 * 60 * 1000) : new Date();


        const handleDepartureDateChange = (date) => {
            setDepartureDate(date);
            setArrivalDate(null);
        };

        const handleDepartureDateChangeMobile = (date) => {
          setDepartureSelect(true)
          setDepartureDate(date);
          setArrivalDate(null);
        };

        const handleArrivalDateChange = (date) => {
            if (date >= departureDate) {
            setArrivalDate(date);
            } else {
            alert('Please select an arrival date after the departure date');
            }
        };

        const handleArrivalDateChangeMobile = (date) => {
          if (date >= departureDate) {
          setArrivalDate(date);
          } else {
          alert('Please select an arrival date after the departure date');
          }
          if (selectedCountry && selectedPeople && departureDate ) {   
            handleShow();
          } else {
            alert('Please fill in all the required fields');
          }
      };
        
        const CustomInput = ({ value, onClick, label, placeholder }) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {label && <label style={{ marginBottom: '4px', fontSize: '14px' }}>{label}</label>}
              <input
                type="text"
                value={value}
                onClick={onClick}
                placeholder={placeholder}
                className="datePicker1"
                readOnly
              />
            </div>
          );

          const CustomInput1 = ({ value, onClick, label, placeholder }) => (
            <div className="date-div-2">
              {label && <label style={{ marginBottom: '4px', fontSize: '14px' }}>{label}</label>}
              <input
                type="text"
                value={value}
                onClick={onClick}
                placeholder={placeholder}
                className="datePicker2"
                readOnly
              />
            </div>
          );

          const renderCustomDayContents = (day, date) => {
        
            return (
              <div style={{height: '2px'}}>
                <span>{day}</span>
                <br />
              </div>
            );
          };      

          const renderCustomDayContents1 = (day) => {
        
            return (
              <div style={{height: '2px'}}>
                <span>{day}</span>
                <br />
              </div>
            );
          };

          const today = new Date();

          const [currentMonth, setCurrentMonth] = useState(new Date());
          const [currentMonth1, setCurrentMonth1] = useState(new Date());

          const filterDate = (date) => {
            if (currentMonth.getMonth() !== date.getMonth()) {
              return false;
            }
            return true;
          };

          const filterDate1 = (date) => {
            if (currentMonth1.getMonth() !== date.getMonth()) {
              return false;
            }
            return true;
          };

          const handleMonthChange = (date) => {
            setCurrentMonth(date);
          };
      
          const handleMonthChange1 = (date) => {
            setCurrentMonth1(date);
          };

    return (
        <div>
            <CustomNavbar />
            <div className="background-containers">
                <div className="background-image" style={{ backgroundImage: `url(${isMobile ? mobileBackgroundImage : backgroundImage1})` }}>
                <div className="text-container">
                {!showBar ? (    
                    <div className="text-container" style={{width: '100%'}}>
                    <h3 className="visaHeading">Where to?</h3>
                    <button className="visaEntrance" onClick={handleShowBar}>
                        <span className='destinationVisaPick'>
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="locationVisaPin"/>
                            Select Your Destination
                        </span>
                        <FontAwesomeIcon icon={faSearch} className="locationSearchPin"/>
                    </button>
                    </div>
                ) : (
                  !isMobile ? (
                <div>
                <h3 className="visaHeading1">Where to?</h3>
                <Navbar className="bg-body-tertiary justify-content-between" style={{ padding: '1.5em 2em', borderRadius: '1em' }}>
                    <div className="Navbar" style={{ display: 'flex', alignItems: 'center' }}>
                        <SelectDestination
                            selectedDestination={selectedCountry}
                            onDestinationChange={setSelectedCountry}
                        />
                        <SelectPassengerCount
                            passengerCount={selectedPeople}
                            onPassengerCountChange={setSelectedPeople}
                        />
                        <div className="select-box" style={{ display: 'flex', alignItems: 'center', marginLeft: '18px' }}>
                            <FontAwesomeIcon icon={faCalendar} className="date-icon-1" />
                            <DatePicker
                            selected={departureDate}
                            onChange={handleDepartureDateChange}
                            onMonthChange={handleMonthChange}
                            minDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select departure date"
                            customInput={<CustomInput placeholder="Select departure date" />}
                            renderDayContents={renderCustomDayContents}
                            filterDate={filterDate}
                            className="react-datepicker__input"
                            />
                        </div>
                        <div className="select-box" style={{ display: 'flex', alignItems: 'center' }}>
                            <FontAwesomeIcon icon={faCalendar} className="date-icon-2" />
                            <DatePicker
                                selected={arrivalDate}
                                onChange={handleArrivalDateChange}
                                onMonthChange={handleMonthChange1}
                                minDate={minArrivalDate}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Select arrival date"
                                customInput={<CustomInput1 placeholder="Select arrival date" />}
                                renderDayContents={renderCustomDayContents1}
                                filterDate={filterDate1}
                                className="react-datepicker__input"
                            />
                        </div>
                        </div>
                        <div className="Button">
                            <Button variant="primary" type="submit" style={{ width: '3.5em', height: 'auto', backgroundColor: 'rgb(35, 35, 142)', borderRadius: '17px' }} onClick={handleSubmit1}><FontAwesomeIcon icon={faSearch} /></Button>
                        </div>
                </Navbar>
                </div>) : (
                  !countrySelected ? (
                  <div>
                    <h3 className="visaHeading1">Where to?</h3>
                    <Navbar className="bg-body-tertiary justify-content-between" style={{ padding: '1.5em 2em', borderRadius: '1em' }}>
                      <div className="Navbar" style={{ display: 'flex', alignItems: 'center' }}>
                        <SelectDestinationMobile
                            selectedDestination={selectedCountry}
                        />
                      </div>
                    </Navbar>
                  </div>
                ) : (
                  !peopleSelected ? (
                  <div>
                    <h3 className="visaHeading1">Total Passengers</h3>
                    <Navbar className="bg-body-tertiary justify-content-between" style={{ padding: '1.5em 2em', borderRadius: '1em' }}>
                      <div className="Navbar" style={{ display: 'flex', alignItems: 'center' }}>
                      <SelectPassengerCountMobile
                            passengerCount={selectedPeople}
                      />
                      </div>
                    </Navbar>
                  </div>
                ) : (
                  !departureSelect ? (
                <div>
                  <h3 className="visaHeading1">Departure Date</h3>
                      <Navbar className="bg-body-tertiary justify-content-between" style={{ padding: '0.5em 0.5em', borderRadius: '1em' }}>
                      <FontAwesomeIcon icon={faCalendar} className="date-icon-2" />
                      <div className="Navbar" style={{ display: 'flex', alignItems: 'center' }}>
                      <DatePicker
                            selected={departureDate}
                            onChange={handleDepartureDateChangeMobile}
                            onMonthChange={handleMonthChange}
                            minDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select departure date"
                            customInput={<CustomInput placeholder="Select departure date" />}
                            renderDayContents={renderCustomDayContents}
                            filterDate={filterDate}
                            className="react-datepicker__input"
                       />
                      </div>
                    </Navbar>
                </div>
                ) : (
                  <div>
                    <h3 className="visaHeading1">Arrival Date</h3>
                    <Navbar className="bg-body-tertiary justify-content-between" style={{ padding: '0.5em 0.5em', borderRadius: '1em' }}>
                      <FontAwesomeIcon icon={faCalendar} className="date-icon-2" />
                      <div className="Navbar" style={{ display: 'flex', alignItems: 'center' }}>
                      <DatePicker
                        selected={arrivalDate}
                        onChange={handleArrivalDateChangeMobile}
                        onMonthChange={handleMonthChange1}
                        minDate={minArrivalDate}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select arrival date"
                        customInput={<CustomInput1 placeholder="Select arrival date" />}
                        renderDayContents={renderCustomDayContents1}
                        filterDate={filterDate1}
                        className="react-datepicker__input"
                      />
                      </div>
                    </Navbar>
                  </div>
                )
                )
                )
                ))}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Enter Your Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="formName">
                                <Form.Label>Name <spam style={{color: 'red', fontWeight: '900'}}>*</spam></Form.Label>
                                <Form.Control type="text" value={name} onChange={handleNameChange} placeholder="Enter your name" />
                            </Form.Group>
                            <Form.Group controlId="formName">
                                <Form.Label>Email-ID <spam style={{color: 'red', fontWeight: '900'}}>*</spam></Form.Label>
                                <Form.Control type="text" value={email} onChange={handleEmailChange} placeholder="Enter your email-id" />
                            </Form.Group>
                            <Form.Group controlId="formName">
                                <Form.Label>Phone Number <spam style={{color: 'red', fontWeight: '900'}}>*</spam></Form.Label>
                                <Form.Control type="phoneNumber" value={phone} onChange={handlePhoneChange} placeholder="Enter your Phone Number" />
                            </Form.Group>
                            <Form.Group controlId="formName">
                                <Form.Label>Anything we need to know?</Form.Label>
                                <Form.Control as="textarea" value={suggestion} onChange={handleSuggestionChange} rows={3} placeholder="Suggestions?" />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleSubmit}>
                          Enquire Now
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Form Submitted Successfully</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        We will be contacting you within 24 hours. Thank you!
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>  
                </div>
                <div>
                    
                </div>
                </div>
            </div>
        </div>
    )
}

export default Visa