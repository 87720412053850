import {React, useEffect} from 'react';
import CustomNavbar from './Navbar';
import Footer from './footer';
import './Home.css';
import OurStoryHead from './images/OurStoryHead.webp'
import GroupProvide from './images/group-provide.svg'
import WomenProvide from './images/woman-provide.svg'
import CorporateProvide from './images/corporate-provide.svg'
import CustomiseProvide from './images/customise-provide.svg'
import BackpackProvide from './images/backpack-provide.svg'
import LuxuryProvide from './images/luxury-provide.svg'
import FamilyProvide from './images/family-provide.svg'
import CoupleProvide from './images/couple-provide.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col } from 'react-bootstrap';
import { faMapLocationDot, faPassport, faPlaneCircleCheck, faHotel, faIdCard, faCar } from '@fortawesome/free-solid-svg-icons';

function OurStory() {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://static.elfsight.com/platform/platform.js';
        script.setAttribute('data-use-service-core', '');
        script.defer = true;
    
        document.body.appendChild(script);
    
        return () => {
          // Cleanup: remove the script when the component is unmounted
          document.body.removeChild(script);
        };
      }, []);

  return (
    <div>
        <CustomNavbar />
        <div className='our-story-main-div'>
            <div style={{width: '81%', margin: 'auto'}}>
                <h2 style={{textAlign: 'center', fontWeight: '700', fontFamily: 'Times New Roman', fontSize: '2.8em'}}>Discover Our Travel Story</h2>
            </div>
            <div className='our-story-container'>
                <div className='our-story-image-left'>
                    <img className='our-story-head-image' src={OurStoryHead} alt="Flip To Trip Story" />
                </div>
                <div className='our-story-container1'>
                    <div style={{boxShadow: '0px 20px 10em rgba(0, 0, 0, 0.1)', padding: '2.5em', borderRadius: '5px'}}>
                        <p style={{textAlign: 'center', fontFamily: 'Times New Roman', fontWeight: '500'}}>At Flip To Trip, we're passionate about helping people explore the world and create unforgettable travel memories. Our platform offers a personalized and stress-free way to book flights, hotels, and activities for all types of travellers, from solo adventurers to families and couples.</p>
                    </div>
                    <div style={{boxShadow: '0px 20px 10em rgba(0, 0, 0, 0.1)', padding: '2.5em', borderRadius: '5px', marginTop: '1.1em'}}>
                        <p style={{textAlign: 'center', fontFamily: 'Times New Roman', fontWeight: '500'}}>Our team of travel experts is dedicated to providing exceptional customer service, and we work tirelessly to ensure that every traveller has the best possible experience. Whether you're looking for a budget-friendly getaway or a luxury vacation, we have options to suit your needs.</p>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div style={{width: '81%', margin: 'auto'}}>
                <h2 className='home-page-title1'>What We Provide</h2>
            </div>
            <Container className="d-flex" style={{ padding: '1em 1em 0' }}>
                <Row>
                    <Col md={2}>
                    <div className="text-center">
                        <FontAwesomeIcon className='what-provide-icon' icon={faMapLocationDot} />
                        <p className='what-provide-text'>Itinerary (Day-Wise)</p>
                    </div>
                    </Col>
                    <Col md={2}>
                    <div className="text-center">
                        <FontAwesomeIcon className='what-provide-icon' icon={faPassport} />
                        <p className='what-provide-text'>Visa Assistance</p>
                    </div>
                    </Col>
                    <Col md={2}>
                    <div className="text-center">
                        <FontAwesomeIcon className='what-provide-icon' icon={faPlaneCircleCheck} />
                        <p className='what-provide-text'>Flight Bookings</p>
                    </div>
                    </Col>
                    <Col md={2}>
                    <div className="text-center">
                        <FontAwesomeIcon className='what-provide-icon' icon={faHotel} />
                        <p className='what-provide-text'>Hotel Bookings</p>
                    </div>
                    </Col>
                    <Col md={2}>
                    <div className="text-center">
                        <FontAwesomeIcon className='what-provide-icon' icon={faIdCard} />
                        <p className='what-provide-text'>Insurance (Travel & Medical)</p>
                    </div>
                    </Col>
                    <Col md={2}>
                    <div className="text-center">
                        <FontAwesomeIcon className='what-provide-icon' icon={faCar} />
                        <p className='what-provide-text'>Transportation (Car, Bus, Train, Cruise)</p>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <div style={{margin: '1em'}}>
            <div style={{width: '81%', margin: 'auto'}}>
                <h2 className='home-page-title1'>Our Services</h2>
            </div>
            <Container className="d-flex" style={{ padding: '2em 0' }}>
                <Row>
                    <Col md={3}>
                    <div className="text-center">
                        <img src={GroupProvide} className='what-provide-svg' alt="Group Trips" />
                        <p className='what-provide-svg-text'>Group Tours</p>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="text-center">
                        <img src={WomenProvide} className='what-provide-svg' alt="Women Tours" />
                        <p className='what-provide-svg-text'>Woman Only Tours</p>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="text-center">
                        <img src={CorporateProvide} className='what-provide-svg' alt="Corporate Tour" />
                        <p className='what-provide-svg-text'>Corporate Tours</p>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="text-center">
                        <img src={CustomiseProvide} className='what-provide-svg' alt="Customised Trips" />
                        <p className='what-provide-svg-text'>Customized Tours</p>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="text-center">
                        <img src={BackpackProvide} className='what-provide-svg' alt="Backpacking Tours" />
                        <p className='what-provide-svg-text'>Backpacking Tours</p>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="text-center">
                        <img src={LuxuryProvide} className='what-provide-svg' alt="Luxury Trip" />
                        <p className='what-provide-svg-text'>Luxury Tours</p>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="text-center">
                        <img src={FamilyProvide} className='what-provide-svg' alt="Family Tour" />
                        <p className='what-provide-svg-text'>Family Tours</p>
                    </div>
                    </Col>
                    <Col md={3}>
                    <div className="text-center">
                        <img src={CoupleProvide} className='what-provide-svg' alt="Couple Trip" />
                        <p className='what-provide-svg-text'>Couple Tours</p>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <div>
            <div style={{width: '81%', margin: 'auto'}}>
                <h2 className='home-page-title1'>Our Video</h2>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', padding: '3em 0'}}>
                <iframe width="80%" height="400em" src="https://www.youtube.com/embed/WwWq4F4Xwt4?si=iSaY3dtlZjqexFfR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
        <h2 className='home-page-title1'>What our Customers Say</h2>
        <div style={{width: '80%', margin: '3em auto 0'}}>
            <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
            <div class="elfsight-app-a41b1159-7a8a-499c-98cb-49889eebac85" data-elfsight-app-lazy></div>
        </div>
        <div style={{marginTop: '1.2em'}}>
            <h2 className='home-page-title1'>Our Office</h2>
            <div className="map">
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.61036015461!2d77.58164597369107!3d12.932746115731712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae156577acafbb%3A0xd5c0f2d91c4cd3a2!2sFlip%20To%20Trip!5e0!3m2!1sen!2sin!4v1700731694601!5m2!1sen!2sin" 
                    width="100%" 
                    height="300em" 
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
        </div>
        <Footer />
    </div>
  );
}

export default OurStory;