import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Customise from './customise';
import Visa from './visa';
import NotFound from './notFound';
import CancellationPage from './cancellation';
import PrivacyPolicy from './privacy';
import TermsAndConditions from './T&C';
import FAQ from './FAQ';
import ContactUs from './contactUs';
import OurStory from './OurStory';
import GoogleAnalytics from './GoogleAnalytics';

function App() {
  return (
    <div>
      <GoogleAnalytics />
      <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/customise-trip" element={<Customise />} />
        <Route path="/visa" element={<Visa />} /> 
        <Route path="/Refund&Cancel" element={<CancellationPage />} />
        <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
        <Route path="/T&C" element={<TermsAndConditions />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path='/Contact-Us' element={<ContactUs />} />
        <Route path='/Our-Story' element={<OurStory />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;