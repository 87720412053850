import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from './images/logo.png'; // Import your logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faWhatsapp,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './footer.css';


const Footer = () => {

  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-dark text-white" style={{padding: '3em 3em 1em'}}>
      <Container>
        <Row>
          <Col xs={12} md={3}>
            <img src={logo} alt="Logo" className='logo-footer-company' />
            <ul className='footer-main-tagline'>
                Looking for a hassle-free way to plan your next travel adventure? Look no further than #fliptotrip! 
            </ul>
            <div className="social-media-links">
                <a href="https://www.facebook.com/fliptotriptravel/" target='_blank'>
                    <FontAwesomeIcon icon={faFacebook} className='social-link-content'/>
                </a>
                <a href="https://www.youtube.com/@fliptotrip" target='_blank'>
                    <FontAwesomeIcon icon={faYoutube} className='social-link-content'/>
                </a>
                <a href="https://www.instagram.com/flip2trip/" target='_blank'>
                    <FontAwesomeIcon icon={faInstagram} className='social-link-content'/>
                </a>
                <a href="https://www.linkedin.com/company/fliptotrip/" target='_blank'>
                    <FontAwesomeIcon icon={faLinkedin} className='social-link-content'/>
                </a>
                <a href="https://api.whatsapp.com/send/?phone=919740096300&text&type=phone_number&app_absent=0" target="_blank">
                    <FontAwesomeIcon icon={faWhatsapp} className='social-link-content'/>
                </a>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <h4 style={{color:'white', fontWeight: '800', paddingBottom: '0.7em'}}>Support</h4>
            <ul className="list-unstyled">
              <li><a href="/Refund&Cancel" className='footer-link-content'>Cancellation and Refund Policy</a></li>
              <li><a href="/Contact-Us" className='footer-link-content'>Contact Us</a></li>
              <li><a href="/Privacy-Policy" className='footer-link-content'>Privacy Policy</a></li>
              <li><a href="/T&C" className='footer-link-content'>Terms and Conditions</a></li>
            </ul>
          </Col>
          <Col xs={12} md={3}>
            <h4 style={{color:'white', fontWeight: '800', paddingBottom: '0.7em'}}>About Us</h4>
            <ul className="list-unstyled">
              <li><a href="/Our-Story" className='footer-link-content'>Our Story</a></li>
              <li><a href="/FAQ" className='footer-link-content'>FAQ</a></li>
              <li><a href="https://www.akhilamaram.com/" target="_blank" className='footer-link-content'>Travel Blog</a></li>
            </ul>
          </Col>
          <Col xs={12} md={3}>
            <h4 style={{color:'white', fontWeight: '800', paddingBottom: '0.7em'}}>Contact Info</h4>
            <ul className="list-unstyled">
              <li style={{color:'white', fontSize: '0.85em'}}><b>Bengaluru: &nbsp;</b>No 261, 9th A Main, Jayanagar 3rd Block - Bengaluru, Karnataka 560011</li>
              <li style={{color:'white', fontSize: '0.85em', marginTop: '-0.6em'}}><b>Chennai: &nbsp;</b>30/49 North Usman Road T Nagar - Chennai, 600017</li>
              <li><a href='tel:+919740096300' className='footer-link-content'>+91 9740096300</a></li>
              <li><a href='mailto:contact@fliptotrip.com' className='footer-link-content'>contact@fliptotrip.com</a></li>
            </ul>
          </Col>
        </Row>
      </Container>
      <hr />
      <div>
        <p className='footer-copy'>&copy; {currentYear} Flip To Trip | All Rights Reserved.</p>
        <p className='footer-copy-1'>Designed and Developed by <a href="https://tarakrammaram.com/" target='_blank' style={{color: '#fff', textDecoration: 'none', fontWeight: '700'}}>Tarakram Maram</a></p>
        <p className='footer-copy-2'>Powered by <a href="https://www.visiondigitalindia.com/" target='_blank' style={{color: '#fff', textDecoration: 'none', fontWeight: '700'}}>Vision India</a></p>
      </div>
    </footer>
  );
};

export default Footer;
