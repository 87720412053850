import React from 'react';
import CustomNavbar from './Navbar';
import Footer from './footer';

const TermsAndConditions = () => {
  const styles = {
    termsContainer: {
        margin: '7em auto 3em',
      fontFamily: 'Arial, sans-serif',
      width: '90%'
    },
    heading: {
        color: '#333',
      textAlign: 'center',
      paddingBottom: '1em',
      fontWeight: '700'
    },
    sectionHeading: {
      color: '#555',
      marginTop: '20px',
    },
    paragraph: {
      color: '#555',
      lineHeight: '1.5',
    },
    strong: {
      fontWeight: 'bold',
    },
    link: {
      color: '#007bff',
      cursor: 'pointer',
    },
  };

  return (
  <div>
    <CustomNavbar />
    <div style={styles.termsContainer}>
      <h2 style={styles.heading}>Terms & Conditions</h2>

      <p style={styles.paragraph}>
        Welcome to Flip to Trip, a travel company dedicated to providing customised and fixed trips for families,
        couples, solo travellers, and woman-only travellers. By using our services, you agree to be bound by these
        Terms and Conditions. Please read them carefully.
      </p>

      <div>
        <h3 style={styles.sectionHeading}>Bookings and Payments:</h3>
          <p>Customised and fixed trips are available for families, couples, solo travellers, and women-only travellers.</p>
          <p>Full payment of the trip cost must be completed before the trip begins.</p>
          <p>Pending payments may lead to the cancellation of the trip.</p>
          <p>The transfer of bookings is not permitted.</p>
          <p>Only the names mentioned at the time of confirmation shall be allowed to travel.</p>
      </div>

      <div>
        <h3 style={styles.sectionHeading}>Passport Validity:</h3>
        <p style={styles.paragraph}>All IDs shall be verified before boarding.</p>
        <p style={styles.paragraph}>No boarding shall be entertained without a valid government-issued ID.</p>
        <p styles={styles.paragraph}>Any denial of entry at immigration, and any additional cost arising out of alternate arrangements for deportation has to be borne by the travellers.</p>
      </div>

      <div>
        <h3 style={styles.sectionHeading}>Luggage and Belongings:</h3>
        <p style={styles.paragraph}>Travellers must take care of their luggage & belongings.</p>
        <p style={styles.paragraph}>The management shall not be responsible for any damage or any missing items along the tour.</p>
      </div>

      <div>
        <h3 style={styles.sectionHeading}>Drinking and Smoking:</h3>
        <p style={styles.paragraph}>Drinking and smoking are strictly prohibited along the tour due to health & safety concerns.</p>
        <p style={styles.paragraph}>Our tours involve physically demanding activities such as trekking, and camping at high altitudes and we recommend that you be in the right shape to make the most of them.</p>
      </div>

      <div>
        <h3 style={styles.sectionHeading}>Departure Time:</h3>
        <p style={styles.paragraph}>The time of departure is stated & fixed.</p>
        <p style={styles.paragraph}>All travellers must update their status with the Trip coordinator(s), & report at the pickup point 30 mins prior to the scheduled departure.</p>
        <p styles={styles.paragraph}>Anyone missing the bus shall not be eligible for any refunds.</p>
        <p styles={styles.paragraph}>We shall call you twice before the scheduled departure.</p>
      </div>

      <div>
        <h3 style={styles.sectionHeading}>Risks and Liability:</h3>
        <p style={styles.paragraph}>Flip to Trip is not responsible for any accident, bodily injury, illness or death, loss or damage to baggage or property, or for any damages or claims whatsoever arising from loss, negligence, or delay from the act, error, omission default or negligence of any person not its direct employee or under its exclusive control.</p>
        <p styles={styles.paragraph}></p>
      </div>

      <div>
        <h3 style={styles.sectionHeading}>Delays and Alterations:</h3>
        <p style={styles.paragraph}>Flip to Trip shall not be responsible for any delays or alterations in the program or indirectly incurred expenses in cases such as natural hazards, accidents, breakdown of machinery, weather conditions, landslides, political closure or any untoward incidents.</p>
      </div>

      <div>
        <h3 style={styles.sectionHeading}>Conduct:</h3>
        <p style={styles.paragraph}>
No act of misconduct or indiscipline shall be tolerated on the tours.</p>
      </div>

      <div>
        <h3 style={styles.sectionHeading}>Itinerary Changes:</h3>
        <p style={styles.paragraph}>Numerous factors such as weather and road conditions may necessitate changes to the itinerary.</p>
        <p styles={styles.paragraph}>The decision to modify the itinerary shall rest solely with the Trip coordinator(s).</p>
      </div>

      <div>
        <h3 style={styles.sectionHeading}>Medical Assistance:</h3>
        <p style={styles.paragraph}>We do not provide medical assistance or facilities on the tour.</p>
        <p styles={styles.paragraph}>If you have a pre-existing medical condition, we advise you to consult your physician and carry your necessary medication with you.</p>
      </div>

      <div>
        <h3 style={styles.sectionHeading}>Photography and Videography:</h3>
        <p style={styles.paragraph}>We may take photographs and videos during the tour, which may be used for promotional purposes.</p>
        <p styles={styles.paragraph}>If you do not wish to be photographed or filmed, kindly inform the Trip coordinator(s) in advance.</p>
      </div>

      <div>
        <h3 style={styles.sectionHeading}>Privacy Policy:</h3>
        <p style={styles.paragraph}>The information you provide to us shall be kept confidential and shall not be disclosed to any third party without your consent.</p>
      </div>

      <div>
        <h3 style={styles.sectionHeading}>Governing Law and Jurisdiction:</h3>
        <p style={styles.paragraph}>These terms and conditions shall be governed by and construed in accordance with the laws of India.</p>
        <p styles={styles.paragraph}>Any disputes arising out of or related to these terms and conditions shall be subject to the exclusive jurisdiction of the courts of India.</p>
      </div>

      <div>
        <h3 style={styles.sectionHeading}>Amendments:</h3>
        <p style={styles.paragraph}>Flip to Trip reserves the right to amend these terms and conditions at any time without prior notice. Any amendments made shall be effective immediately upon posting on our website.</p>
      </div>
      
      <div style={styles.link}>
        <h3 style={styles.sectionHeading}>Contact Information:</h3>
        <p style={styles.paragraph}>
          If you have any questions or concerns regarding these terms and conditions, please contact us at{' '}
          <a href="mailto:contact@fliptotrip.com" style={styles.link}>contact@fliptotrip.com</a>.
        </p>
      </div>
    </div>
    <Footer />
</div>
  );
};

export default TermsAndConditions;
