import React from 'react';
import CustomNavbar from './Navbar';
import Footer from './footer';
import './Home.css';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp, faFacebook, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faLocationDot, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';


function ContactUs() {
  return (
    <div>
        <CustomNavbar />
        <div className="map1">
            <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.61036015461!2d77.58164597369107!3d12.932746115731712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae156577acafbb%3A0xd5c0f2d91c4cd3a2!2sFlip%20To%20Trip!5e0!3m2!1sen!2sin!4v1700731694601!5m2!1sen!2sin" 
            width="100%" 
            height="400em" 
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade">
            </iframe>
        </div>
        <h2 className='text-center' style={{ fontWeight: '800', color: '#16086b', padding: '1em 1em 0.5em', fontSize:'3em' }}>Talk To Us</h2>
        <div className='contact-list-container'>
        <Container className="mt-5">
          <Row className='contact-list-container'>
            <Col md={6}>
              <h3 className='contact-list-title-1'>Contact Details</h3>
              <p className='text-of-contact'>
                <a className='contact-link-text' href="tel:+919740096300"><FontAwesomeIcon icon={faPhone} /> +91 97400 96300</a>
              </p>
              <p className='text-of-contact'>
                <a className='contact-link-text' href="mailto:contact@fliptotrip.com"><FontAwesomeIcon icon={faEnvelope} /> contact@fliptotrip.com</a>
              </p>
              <p className='text-of-contact'>
                <FontAwesomeIcon icon={faLocationDot} /><b>&nbsp; Bengaluru:</b> No 261, 9th A Main, Jayanagar 3rd Block, Bengaluru, Karnataka 560011
              </p>
              <p className='text-of-contact'>
                <FontAwesomeIcon icon={faLocationDot} /><b>&nbsp; Chennai:</b> 30/49 North Usman Road T Nagar Chennai -600017
              </p>
            </Col>

            {/* Social Media Links */}
            <Col md={6}>
              <h3 className='contact-list-title'>Follow Us On</h3>
              <div className="social-media-link">
                    <a href="https://www.facebook.com/fliptotriptravel/" target='_blank'>
                        <FontAwesomeIcon icon={faFacebook} style={{color: 'rgb(35, 35, 142)'}}/>
                    </a>
                    <a href="https://www.youtube.com/@fliptotrip" target='_blank'>
                        <FontAwesomeIcon icon={faYoutube} style={{color: 'red'}}/>
                    </a>
                    <a href="https://www.instagram.com/flip2trip/" target='_blank'>
                        <FontAwesomeIcon icon={faInstagram} style={{color: '#C13584'}}/>
                    </a>
                    <a href="https://www.linkedin.com/company/fliptotrip/" target='_blank'>
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    <a href="https://api.whatsapp.com/send/?phone=919740096300&text&type=phone_number&app_absent=0" target="_blank">
                        <FontAwesomeIcon icon={faWhatsapp} style={{color: 'green'}}/>
                    </a>
                </div>
            </Col>
          </Row>
        </Container>
        </div>
        <Footer />
    </div>
  );
}

export default ContactUs;