import React from 'react';
import CustomNavbar from './Navbar';
import Footer from './footer';
import Accordion from 'react-bootstrap/Accordion';
import FAQImg from './images/FAQ.webp'
import './Home.css';

function FAQ() {
  return (
    <div>
        <CustomNavbar />
        <div style={{padding: '8em 0'}}>
            <div style={{textAlign: 'center'}}>
                <img src={FAQImg} className='FAQImage' alt="FAQ Flip To Trip" />
                <h3 style={{paddingTop: '1em', fontWeight: '700'}}>Do You Have Some Questions?</h3>
            </div>
            <div className='FAQQuestionDiv'>
                <h3 style={{fontWeight: '700'}}>Common Questions</h3>
                <Accordion defaultActiveKey="0" flush style={{padding: '1em 0'}}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><b>How does Flip to Trip differentiate itself from other travel companies?</b></Accordion.Header>
                    <Accordion.Body>
                    At Flip to Trip, we pride ourselves on our customer-centric approach to travel planning. We believe that each traveler’s journey is unique, and we strive to personalize every aspect of their experience. Our team of experienced travel professionals is dedicated to providing exceptional customer service and working closely with clients to design customized itineraries that meet their specific needs and preferences. We also offer competitive pricing and access to exclusive deals and discounts through our partnerships with airlines, hotels, and other travel providers. Overall, our commitment to personalized service, competitive pricing, and exclusive offerings sets us apart from other travel companies in the industry.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><b>As a relatively new company, how can I be confident in entrusting Flip to Trip with my annual vacation?</b></Accordion.Header>
                    <Accordion.Body>
                    We understand that trust is an essential factor when it comes to choosing a travel company. Although we are a young company, we have a team of experienced professionals who are passionate about providing top-quality service to our clients. We also value transparency and accountability and strive to ensure that our customers feel confident in their decision to choose us. We encourage you to read our customer reviews and testimonials to see how we have successfully helped many travelers plan and enjoy their dream vacations.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><b>How do I book a trip with your agency?</b></Accordion.Header>
                    <Accordion.Body>
                    You can book a trip with us by visiting our website and selecting the destination, travel dates, and any other preferences. Alternatively, you can give us a call or send us an email to speak with one of our travel experts who can help you plan your trip.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header><b>Do you offer travel insurance?</b></Accordion.Header>
                    <Accordion.Body>
                    Yes, we offer travel insurance to protect you from unexpected events such as trip cancellations, medical emergencies, and lost luggage.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header><b>Do you offer group travel discounts?</b></Accordion.Header>
                    <Accordion.Body>
                    Yes, we offer group travel discounts for groups of 10 or more people traveling together. Please contact us for more information and to request a group travel quote.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header><b>What types of payment do you accept?</b></Accordion.Header>
                    <Accordion.Body>
                    We accept various forms of payment including credit cards, debit cards, UPI, Wallets and bank transfers. 
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header><b>How do I obtain visas and travel documents for international travel?</b></Accordion.Header>
                    <Accordion.Body>
                    We will assist you in obtaining visas and travel documents for international travel. Please contact us for more information and to request assistance.
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
            </div>
            <div className='FAQQuestionDiv' style={{marginBottom: '0em'}}>
                <h3 style={{fontWeight: '700'}}>Booking Information​</h3>
                <Accordion defaultActiveKey="0" flush style={{padding: '1em 0'}}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><b>Can I make changes to my itinerary after booking it?</b></Accordion.Header>
                    <Accordion.Body>
                    Yes, you can make changes to your itinerary after booking it, subject to availability and any fees or penalties charged by the travel provider. Please contact us as soon as possible if you need to make any changes.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><b>How can I check the status of my booking?</b></Accordion.Header>
                    <Accordion.Body>
                    You can check the status of your booking by contacting our customer service team.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><b>How do I know my booking is confirmed?</b></Accordion.Header>
                    <Accordion.Body>
                    Once you complete the booking process and make the payment, you will receive a confirmation email from Flip to Trip. This email will contain all the details of your booking, including the booking reference number. You can use this reference number to check your booking status by contacting the customer support team.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header><b>How do I add special requests to my booking?</b></Accordion.Header>
                    <Accordion.Body>
                    If you have any special requests or preferences, such as dietary requirements or room preferences, etc. you can mention them during the booking process in the “Enquiry Form” section. Alternatively, you can contact the customer support team before you have made the booking to request any changes or additions to your booking. We will try our best to accommodate your requests, subject to availability and any applicable fees.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header><b>How can I request a quote via email?</b></Accordion.Header>
                    <Accordion.Body>
                    To request a quote via email, simply send us an email with your travel details, including your destination, travel dates, preferred accommodations, and any other specific requirements you may have. Alternatively you can fill the enquiry form too. Our travel agents will then prepare a customized quote for you based on your preferences and email it back to you. We strive to respond to all quote requests within 24-48 hours. If you have any further questions or concerns, please don’t hesitate to contact us via email or phone.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header><b>How can I request a destination that is not listed on your website?</b></Accordion.Header>
                    <Accordion.Body>
                    If you can’t find your desired destination on our website, don’t worry. We are constantly updating our destinations list, and we may be able to provide you with a customized travel package that fits your needs. You can contact our customer support team through email or phone and provide them with the details of your desired destination, and we will do our best to accommodate your request. Our team will work with you to create a tailored itinerary and provide you with a quote for your trip. We are dedicated to ensuring that our clients have a memorable travel experience, and we look forward to helping you plan your dream vacation.
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
            </div>
        </div>
        <Footer />
    </div>
  );
}

export default FAQ;