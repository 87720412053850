import { initializeApp } from "firebase/app";
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyDX9W4XFRDCDDeFlE8h-aRH8OV424IDqeY",
    authDomain: "fliptotrip-1951c.firebaseapp.com",
    databaseURL: "https://fliptotrip-1951c-default-rtdb.firebaseio.com",
    projectId: "fliptotrip-1951c",
    storageBucket: "fliptotrip-1951c.appspot.com",
    messagingSenderId: "930058086839",
    appId: "1:930058086839:web:0b201c5d9c0690e53a2032",
    measurementId: "G-F35DGQ3NWG"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { app, database };